@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap');
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to left, rgb(27 20 41), rgb(20 15 35));
}

h1 {
  font-weight: 300 !important;
}

h2 {
  font-weight: 300 !important;
}

